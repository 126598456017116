import React from "react";
import { Helmet } from "react-helmet";

export default function Component() {
  return (
    <>
      <Helmet>
        <script>window.location="/";</script>
      </Helmet>
      <div style={{ display: "none" }}>
        <form>
          <div className="has-text-gray admin-original-image admin-thumbnail-image field control has-text-white has-text-semibold width ais-SearchBox-input link-button-gray link-button-orange brand-image ml-1 ml-2 ml-4 mr-4 mr-1 mr-2 mb-0 pb-0 is-1 is-2 is-3 is-4 is-5 is-6 is-7 is-8 pr-1 pr-2 pr-3 pr-4 pl-1 pl-2 pl-3 pl-4 ticket price title___trails-we-shuttle title___riding-in-moab title___departure-locations title___galleries m-4 p-4 p-5 p-6 two-column-layout pt-1 pt-2 pt-3 pt-0 hidden max-img-400 orange orange-1 orange-2 orange-3 orange-4 orange-5 orange-6 is-green is-blue is-black mt-0 mb-0 pb-0 mb-1 pb-2 mb-2 pb-2">
            <div>
              <label for="firstname">
                First name:
                <input type="text" name="firstname" id="firstname" />
              </label>
            </div>
            <div>
              <label for="text">
                First name:
                <textarea name="text" id="text"></textarea>
              </label>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
